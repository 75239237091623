const  DataGridComponent = Formio.Components.components.datagrid;
const editForm = Formio.Components.components.nested.editForm;

 

class CustomDataGridComponent extends DataGridComponent {
    static schema(...extend) {
        return DataGridComponent.schema({
          label: 'Better Data Grid',
          type: 'customDataGrid',
          conditionalRemoveButton: '',
          conditionalDisableRow: '',
        }, ...extend);
      }
      
      static get builderInfo() {
        return {
          title: 'Better Data Grid',
          group: 'data',
          icon: 'th',
          weight: 30,
          documentation: '/userguide/forms/data-components#data-grid',
          schema: CustomDataGridComponent.schema()
        };
      }
  
    init() {
        super.init();
    }
    areRowsDisabled(conditionalDisableRow){
      let values = this.getValue();
      let array = [];
      let data= this._data;
      values.forEach((row) => {
          array.push( eval(conditionalDisableRow));
       });
       return array;
    }
    
    rowsHasRemoveButtons(conditionalRemoveButton){
        let values = this.getValue();
        let array = [];
        //data variable is used by eval function 
        let data= this._data;
        values.forEach((row) => {
          //row is used by eval function  
          array.push( eval(conditionalRemoveButton));
        
        });
     
        return array;
    } 

    getRows(disabledRows) {
      return this.rows.map(row => {
        const components = {};
        _.each(row, (col, key) => {
          let index= this.rows.indexOf(row);
          col.component.disabled = disabledRows[index];
          col._disabled = disabledRows[index];
          components[key] = col.render();
          
        });
        return components;
      });
    }
    
}
CustomDataGridComponent.prototype.render = function(element) {
    const conditionalRemoveButton = _.get(this.component, 'conditionalRemoveButton');
    const conditionalDisableRow = _.get(this.component, 'conditionalDisableRow');

    let disabledRows = this.areRowsDisabled(conditionalDisableRow);

    const columns = this.getColumns();
    let columnExtra = 0;
    const hasRemoveButtons = this.hasRemoveButtons();
    if (this.component.reorder) {
      columnExtra++;
    }
    if (hasRemoveButtons) {
      columnExtra++;
    }
    if (this.canAddColumn) {
      columnExtra++;
    }
    const colWidth = Math.floor(12 / (columns.length + columnExtra));
    return this.renderTemplate('customDataGridTmp', {   
        component: this.component,
        values: this.component.label,
        rows: this.getRows(disabledRows),
        columns: columns,
        groups: this.hasRowGroups() ? this.getGroups() : [],
        visibleColumns: this.visibleColumns,
        hasToggle: _.get(this, 'component.groupToggle', false),
        hasHeader: this.hasHeader(),
        hasExtraColumn: this.hasExtraColumn(),
        hasAddButton: this.hasAddButton(),
        hasRemoveButtons,
        hasTopSubmit: this.hasTopSubmit(),
        hasBottomSubmit: this.hasBottomSubmit(),
        hasGroups: this.hasRowGroups(),
        numColumns: columns.length + (this.hasExtraColumn() ? 1 : 0),
        datagridKey: this.datagridKey,
        allowReorder: this.allowReorder,
        builder: this.builderMode,
        canAddColumn: this.canAddColumn,
        tabIndex: this.tabIndex,
        placeholder: this.renderTemplate('builderPlaceholder', {
            position: this.componentComponents.length,
          }),
        colWidth: colWidth.toString(),
        conditionalRemoveButton:conditionalRemoveButton,
        array:this.rowsHasRemoveButtons(conditionalRemoveButton),
    });
};

/**
 * Create a custom data grid compoennt and extend from the HTMLComponent.
 */
CustomDataGridComponent.editForm = function() {
    return DataGridComponent.editForm([
    {
     weight: 10,

    key:'display',
    components: [
        {
            weight: 500,
            type: 'textarea',
            input: true,
            key: 'conditionalRemoveButton',
            label: 'Conditional remove Button',
            placeholder: 'show = ...',
            tooltip: 'Specify condition when remove Button should be deleted.',
            editor: 'ace',
            as: 'javascript',
            wysiwyg: {
              minLines: 3,
            },
        },
        {
          weight: 500,
          type: 'textarea',
          input: true,
          key: 'conditionalDisableRow',
          label: 'Conditional disable Button',
          placeholder: 'show = ...',
          tooltip: 'Disable specific rows on condition..',
          editor: 'ace',
          as: 'javascript',
          wysiwyg: {
            minLines: 3,
          },
      }
    ]
   }
 ]);
};
Formio.Templates.templates.bootstrap.customDataGridTmp = {
    form:  `
    <div class="builder-component " ref="dragComponent">
    <div data-noattach="true" class="component-btn-group {{ctx.builder?'':'d-none'}}">
      <div ref="removeComponent" class="btn btn-xxs btn-danger component-settings-button component-settings-button-remove" tabindex="-1" aria-label="Remove button. Click to remove component from the form" role="button">
        <i class="fa fa-remove"></i>
      </div>
      <div ref="copyComponent" class="btn btn-xxs btn-default component-settings-button component-settings-button-copy" tabindex="-1" aria-label="Copy button. Click to copy component" role="button">
        <i class="fa fa-copy"></i>
      </div>
      <div ref="pasteComponent" class="btn btn-xxs btn-default component-settings-button component-settings-button-paste" tabindex="-1" aria-label="Paste below button. Click to paste component below the current component" role="button">
        <i class="fa fa-download"></i>
      </div>
      <div ref="editJson" class="btn btn-xxs btn-default component-settings-button component-settings-button-edit-json" tabindex="-1" aria-label="Edit json button. Click to edit json of the current component" role="button">
        <i class="fa fa-wrench"></i>
      </div>
      <div ref="moveComponent" class="btn btn-xxs btn-default component-settings-button component-settings-button-move" tabindex="-1" aria-label="Move button" role="button">
        <i class="fa fa-arrows"></i>
      </div>
      <div ref="editComponent" class="btn btn-xxs btn-secondary component-settings-button component-settings-button-edit" tabindex="-1" aria-label="Edit button. Click to open component settings modal window" role="button">
        <i class="fa fa-cog"></i>
      </div>
    </div>
    <label >{{t(component.label)}}</label>    
    <table class="table datagrid-table table-bordered scrollable
    {{ ctx.component.striped ? 'table-striped' : ''}}
    {{ ctx.component.hover ? 'table-hover' : ''}}
    {{ ctx.component.condensed ? 'table-sm' : ''}}
    " {% if (ctx.component.layoutFixed) { %}style="table-layout: fixed;"{% } %}>
  {% if (ctx.hasHeader) { %}
  <thead>
    <tr>
      {% if (ctx.component.reorder) { %}<th></th>{% } %}
      {% ctx.columns.forEach(function(col) { %}
        <th class="{{col.validate && col.validate.required ? 'field-required' : ''}}">
          {{ col.hideLabel ? '' : ctx.t(col.label || col.title, { _userInput: true }) }}
          {% if (col.tooltip) { %} <i ref="tooltip" tabindex="0" data-title="{{col.tooltip}}" class="{{ctx.iconClass('question-sign')}} text-muted" data-tooltip="{{col.tooltip}}"></i>{% } %}
        </th>
      {% }) %}
      {% if (ctx.hasExtraColumn) { %}
      <th>
        <span class="sr-only">{{ ctx.t('Add/Remove') }}</span>
        {% if (!ctx.builder && ctx.hasAddButton && ctx.hasTopSubmit) { %}
        <button class="btn btn-primary formio-button-add-row" ref="{{ctx.datagridKey}}-addRow" tabindex="{{ctx.tabIndex}}">
          <i class="{{ctx.iconClass('plus')}}"></i>{{ctx.t(ctx.component.addAnother || 'Add Another', { _userInput: true })}}
        </button>
        {% } %}
      </th>
      {% } %}
    </tr>
  </thead>
  {% } %}
  <tbody ref="{{ctx.datagridKey}}-tbody" data-key="{{ctx.datagridKey}}">
    {% ctx.rows.forEach(function(row, index) { %}
    {% if (ctx.hasGroups && ctx.groups[index]) { %}
    <tr ref="{{ctx.datagridKey}}-group-header" class="datagrid-group-header{{ctx.hasToggle ? ' clickable' : ''}}">
      <td
        ref="{{ctx.datagridKey}}-group-label"
        colspan="{{ctx.numColumns}}"
        class="datagrid-group-label">{{ctx.groups[index].label}}</td>
    </tr>
    {% } %}
    <tr ref="{{ctx.datagridKey}}-row">
      {% if (ctx.component.reorder) { %}
        <td>
          <button type="button" class="formio-drag-button btn btn-default fa fa-bars" data-key="{{ctx.datagridKey}}"></button>
        </td>
      {% } %}
      {% ctx.columns.forEach(function(col) { %}
        <td ref="{{ctx.datagridKey}}" {% if (col.key && col.overlay && col.overlay.width) { %} style="width: {{col.overlay.width + 'px'}}"{% } %} >
          {{row[col.key]}}
        </td>
      {% }) %}
      {% if (ctx.hasExtraColumn) { %}
        {% if (ctx.hasRemoveButtons && !ctx.array[index]) { %}
        <td>
          <button type="button" class="btn btn-secondary formio-button-remove-row" ref="{{ctx.datagridKey}}-removeRow" tabindex="{$ ctx.indexes[index] $}" aria-label="{{ctx.t('remove')}}">
            <i class="{{ctx.iconClass('remove-circle')}}"></i>
          </button>
        </td>
        {% } else { %}.
              <td>
                   <div ref="{{ctx.datagridKey}}-removeRow" tabindex="{{ index }}"></div>
              </td>
        {% } %}
        {% if (ctx.canAddColumn) { %}
        <td ref="{{ctx.key}}-container">
          {{ctx.placeholder}}
        </td>
        {% } %}
      {% } %}
    </tr>
    {% }) %}
  </tbody>
  {% if (!ctx.builder && ctx.hasAddButton && ctx.hasBottomSubmit) { %}
  <tfoot>
    <tr>
      <td colspan="{{ctx.component.layoutFixed ? ctx.numColumns :  ctx.numColumns + 1}}">
        <button class="btn btn-primary formio-button-add-row" ref="{{ctx.datagridKey}}-addRow" tabindex="{{ctx.tabIndex}}">
          <i class="{{ctx.iconClass('plus')}}"></i> {{ctx.t(ctx.component.addAnother || 'Add Another', { _userInput: true })}}
        </button>
      </td>
    </tr>
  </tfoot>
  {% } %}
</table>
  </div>
    `
};

  
Formio.use({
    components: {
        customDataGrid: CustomDataGridComponent
    }
});


